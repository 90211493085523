import { ClickOutside } from 'components/click-outside/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
// import { Dataset } from 'generic/dataset'
import React from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import './_styles.scss'
import { NotificationIcon } from './notification-icon'
import { NotificationItem } from './notification-item'
import { NotificationRecord, NotificationProps, NotificationState } from './types'
import { Dataset } from 'generic/dataset'
import { Roles } from 'shared/constants'

class NotificationComponent extends React.Component<NotificationProps, NotificationState> {
  public static contextType = RasaContext;
  private sharedStore: SharedStore
  private communityId: string = null

  constructor(props: NotificationProps) {
    super(props)
    this.state = {
      notifications: [],
      isLoading: true,
      isSuperUser: false,
      selected: null,
      showList: false,
    }
  }
  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.role),
    ])
    .then(([activeCommunity, role]) => {
      this.communityId = activeCommunity.communityId
      if (role === Roles.super_admin) {
        this.setState({isSuperUser: role === Roles.super_admin}, () => {
          this.loadNotifications()
          .then((notifications: NotificationRecord[]) => {
            this.setState({
              notifications,
              isLoading: false,
            })
          })
        })
      } else {
        this.setState({isLoading: false})
      }
    })
  }
  public render() {
    return this.state.notifications.length > 0 ? this.renderNotifications() : null
  }

  private renderNotifications = () => {
    return this.state.isLoading
    ? <Loading size="64" />
    : <div className="notifications">
        <ClickOutside handleClickOutside={() => {
          if (this.state.showList) {
            this.setState({showList: !this.state.showList})
          }
        }}>
          <div className="notification-wrapper">
            <div className="notification-icon-wrapper" onClick={this.toggle}>
              <NotificationIcon count={this.unreadNotifications().length} />
            </div>
            {this.state.showList &&
              <div className="notification-items-wrapper">
                <div className="notification-header">
                  <div className="notification-header-label">Notifications</div>
                  <div className="close-button" onClick={this.toggle}>X</div>
                </div>
                <div className="notification-items">
                  {(this.state.notifications).map((item, i) => {
                    return <NotificationItem item={item} onClick={this.onNotificationItemClick} readToggle={this.onNotificationItemReadToggle} />
                  })}
                </div>
              </div>
            }
          </div>
        </ClickOutside>
      </div>
  }

  private unreadNotifications = () => {
    return this.state.notifications.filter((notification) => !notification.is_read)
  }

  private toggle = () => {
    this.setState({
      showList: !this.state.showList,
    })
  }
  private onNotificationItemClick = (item: NotificationRecord) => {
    // link to corresponding page if available
    // mark notification as read
    if (item.link_url) {
      // send to link page if available
      if (item.link_url.startsWith('https://')) {
        // open in new tab
        window.open(item.link_url, '_blank')
      } else {
        // navigate to page
        this.props.push(item.link_url)
      }
      this.toggle()
    }
  }
  private onNotificationItemReadToggle = (item: NotificationRecord, isRead): Promise<boolean> => {
    // mark notification as read
    return this.updateNotification(item, {is_read: isRead})
  }
  private updateNotification = (item: NotificationRecord, payload): Promise<boolean> => {
    return this.context.entityMetadata.getEntityObject('userNotification', this.communityId, item.notification_id)
    .then((entityObject: any) => {
      if (payload.hasOwnProperty('is_read')) {
        entityObject.data.is_read = payload.is_read
      }
      return entityObject.save()
      .then(() => {
        return this.loadNotifications()
        .then((notifications: NotificationRecord[]) => {
          this.setState({notifications})
          return true
        })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error updating notification', error)
        return false
      })
    })
  }
  private loadNotifications = (): Promise<NotificationRecord[]> => {
    return new Dataset().loadCommunityDataset('userNotifications', this.communityId)
    .then((notificationsResult) => {
      return this.transformNotifications(notificationsResult[0])
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error loading notifications', error)
      return []
    })
  }
  private transformNotifications = (notifications: any[]): NotificationRecord[] => {
    const transformNotifications = notifications.map((notification) => {
      return {
        ...notification,
        created_at: new Date(notification.created).toLocaleDateString(),
        link_url: this.getNotificationItemLink(notification),
      }
    })
    return transformNotifications
  }
  private getNotificationItemLink = (notification: NotificationRecord) => {
    return notification.link_url || ''
  }
}

export const Notification = connect(
  null,
  {
    push: Router.push,
  },
)(NotificationComponent)
